import React from 'react';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline, Body } from '@sumup/circuit-ui';
import { BLOCKS, INLINES, MARKS } from '@contentful/rich-text-types';
import isPropValid from '@emotion/is-prop-valid';
import { MergeTag } from '@ninetailed/experience.js-next';

import EmbeddedVariable from '../components/EmbeddedVariable';
import { getFields, getContentType } from '../util';

import Anchor from '~/shared/components/Anchor';
import slugify from '~/shared/util/slugify';
import { NINETAILED_IS_ENABLED } from '~/shared/services/ninetailed/constants';

const contentStyles = ({ theme }) => css`
  margin-top: ${theme.spacings.giga};
  margin-bottom: ${theme.spacings.mega};
`;

const marginBottomStyles = ({ theme }) => css`
  margin-bottom: ${theme.spacings.mega};
`;
const contentSoloColorStyles = ({ soloDesign = {} }) =>
  soloDesign.textColor &&
  css`
    color: ${soloDesign.textColor};
  `;

const contentSoloStyles = ({ theme, soloDesign = {} }) => css`
  ${theme.mq.kilo} {
    ${soloDesign.heading2FontSize &&
    css`
      font-size: ${soloDesign.heading2FontSize};
    `}
    ${soloDesign.heading2LineHeight &&
    css`
      line-height: ${soloDesign.heading2LineHeight};
    `}
  }

  ${theme.mq.untilKilo} {
    ${soloDesign.heading2FontSizeMobile &&
    css`
      font-size: ${soloDesign.heading2FontSizeMobile};
    `}
    ${soloDesign.heading2LineHeightMobile &&
    css`
      line-height: ${soloDesign.heading2LineHeightMobile};
    `}
  }
`;

const StyledContent = styled(Headline)(
  contentStyles,
  contentSoloColorStyles,
  contentSoloStyles,
);
const StyledContentBody = styled(Body)(
  contentSoloColorStyles,
  marginBottomStyles,
);

const StyledContentBold = styled(Body)(
  ({ theme }) => css`
    margin-bottom: ${theme.spacings.giga};
    font-size: inherit !important;
  `,
);

const StyledContentItalic = styled(Body)(css`
  font-size: inherit !important;
  font-weight: inherit !important;
  font-style: italic;
`);

const headlineBaseStyles = ({ theme, soloDesign }) => css`
  font-weight: ${theme.fontWeight.regular};
  margin-bottom: ${theme.spacings.giga};

  ${theme.mq.untilKilo} {
    ${soloDesign.headlineFontSizeMobile &&
    css`
      font-size: ${soloDesign.headlineFontSizeMobile};
    `}
    ${soloDesign.headlineLineHeightMobile &&
    css`
      line-height: ${soloDesign.headlineLineHeightMobile};
    `}
  }

  ${theme.mq.kilo} {
    ${soloDesign.headlineFontSize &&
    css`
      font-size: ${soloDesign.headlineFontSize};
    `}
    ${soloDesign.headlineLineHeight &&
    css`
      line-height: ${soloDesign.headlineLineHeight};
    `}
  }
`;

const StyledHeadline = styled(Headline, {
  shouldForwardProp: (prop) => prop === 'as' || isPropValid(prop),
})(headlineBaseStyles);

const StyledHeadlineBold = styled(Headline)(
  ({ theme }) => css`
    font-size: inherit !important;
    font-weight: ${theme.fontWeight.bold};
  `,
);

const StyledHeadlineItalic = styled(Headline)(
  ({ theme }) => css`
    font-size: inherit !important;
    font-weight: inherit !important;
    font-style: italic;
    margin-bottom: ${theme.spacings.giga};
  `,
);

function createContentRenderNode(
  size = 'one',
  trackingContentEntry = {},
  additionalProps = { text: {}, as: '' },
) {
  return {
    [BLOCKS.HEADING_1]: (node, children) => (
      <StyledContent
        as={additionalProps.as || node.as}
        size="one"
        id={slugify(children)}
      >
        {children}
      </StyledContent>
    ),
    [BLOCKS.HEADING_2]: (node, children) => (
      <StyledContent
        as={additionalProps.as || node.as}
        size="one"
        id={slugify(children)}
      >
        {children}
      </StyledContent>
    ),
    [BLOCKS.HEADING_3]: (node, children) => (
      <StyledContent
        as={additionalProps.as || node.as}
        size="two"
        id={slugify(children)}
      >
        {children}
      </StyledContent>
    ),
    [BLOCKS.HEADING_4]: (node, children) => (
      <StyledContent
        as={additionalProps.as || node.as}
        size="three"
        id={slugify(children)}
      >
        {children}
      </StyledContent>
    ),
    [BLOCKS.HEADING_5]: (node, children) => (
      <StyledContent
        as={additionalProps.as || node.as}
        size="four"
        id={slugify(children)}
      >
        {children}
      </StyledContent>
    ),
    [BLOCKS.HEADING_6]: (node, children) => (
      <StyledContent
        as={additionalProps.as || node.as}
        size="four"
        id={slugify(children)}
      >
        {children}
      </StyledContent>
    ),
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <StyledContentBody
        size="one"
        as={additionalProps.as || node.as}
        {...additionalProps.text}
      >
        {children}
      </StyledContentBody>
    ),
    [INLINES.HYPERLINK]: (node, children) => (
      <Anchor
        href={node.data.uri}
        trackingContentEntry={trackingContentEntry}
        size={size}
      >
        {children}
      </Anchor>
    ),
    [INLINES.EMBEDDED_ENTRY]: (node) => {
      const contentType = getContentType(node);
      const fields = getFields(node);

      if (
        node?.data?.target?.contentType === 'nt_mergetag' &&
        node?.data?.target?.nt_mergetag_id &&
        NINETAILED_IS_ENABLED
      ) {
        return <MergeTag id={node.data.target.nt_mergetag_id} />;
      }

      switch (contentType) {
        case 'embeddedVariable':
          return <EmbeddedVariable {...fields} />;
        default:
          return null;
      }
    },
  };
}

function createContentRenderMark(size = 'one') {
  const defaultProps = {
    size,
  };

  return {
    [MARKS.BOLD]: (text) => (
      <StyledContentBold {...defaultProps} as="strong" variant="highlight">
        {text}
      </StyledContentBold>
    ),
    [MARKS.ITALIC]: (text) => (
      <StyledContentItalic {...defaultProps} as="em">
        {text}
      </StyledContentItalic>
    ),
  };
}

function createHeadlineRenderNode({ as, soloDesign, dataSelector }) {
  return {
    [BLOCKS.PARAGRAPH]: (node, children) => (
      <StyledHeadline
        size="one"
        as={as}
        id={slugify(children)}
        dataSelector={dataSelector}
        soloDesign={soloDesign}
      >
        {children}
      </StyledHeadline>
    ),
  };
}

const createHeadlineRenderMark = {
  [MARKS.BOLD]: (text) => (
    <StyledHeadlineBold as="strong">{text}</StyledHeadlineBold>
  ),
  [MARKS.ITALIC]: (text) => (
    <StyledHeadlineItalic as="em">{text}</StyledHeadlineItalic>
  ),
};

export const contentConfig = {
  createRenderNode: createContentRenderNode,
  createRenderMark: createContentRenderMark,
};

export const headlineConfig = {
  createRenderNode: createHeadlineRenderNode,
  createRenderMark: createHeadlineRenderMark,
};
