// eslint-disable-next-line import/prefer-default-export
export const pageWidthColProps = {
  span: { default: 12, kilo: 10, mega: 8 },
  skip: { default: 0, kilo: 1, mega: 2 },
};

export const pageFullWidthColProps = {
  span: { default: 12 },
  skip: { default: 0 },
};

export const storylanePageWidthColProps = {
  span: { default: 12 },
  skip: { default: 0 },
};
