import React from 'react';
import PropTypes from 'prop-types';
import styled from '@emotion/styled';
import { css } from '@emotion/react';
import { Headline } from '@sumup/circuit-ui';
import { Col, Row } from '@sumup/circuit-ui/legacy';
import isEmpty from 'lodash/fp/isEmpty';
import get from 'lodash/fp/get';
import isPropValid from '@emotion/is-prop-valid';

import { ALIGNMENT } from '~/shared/constants';
import slugify from '~/shared/util/slugify';
import dataSelector from '~/shared/util/data-selector';
import {
  richTextPropType,
  trackingContentEntryPropType,
} from '~/shared/util/shared-prop-types';
import { pageWidthColProps } from '~/shared/styles/helpers';
import RichText from '~/shared/components/RichText';
import * as sectionHeader from '~/shared/components/RichText/configs/section-header';

const baseRowStyles = ({ theme }) => css`
  margin-bottom: ${theme.spacings.peta};

  *:last-child {
    margin-bottom: 0;
  }
`;

const centerRowStyles = ({ alignment }) =>
  alignment === ALIGNMENT.CENTER &&
  css`
    text-align: center;
  `;

const StyledRow = styled(Row)(baseRowStyles, centerRowStyles);

const headlineSoloStyles = ({ theme, soloDesign = {} }) => css`
  margin-bottom: ${theme.spacings.giga};
  ${soloDesign.textColor &&
  css`
    color: ${soloDesign.textColor};
  `}

  ${theme.mq.kilo} {
    ${soloDesign.headlineFontSize &&
    css`
      font-size: ${soloDesign.headlineFontSize};
      word-break: break-word;
      text-wrap: balance;
    `}
    ${soloDesign.headlineLineHeight &&
    css`
      line-height: ${soloDesign.headlineLineHeight};
    `}
  }
`;
const headlineSoloStylesMobile = ({ theme, soloDesign = {} }) => css`
  ${theme.mq.untilKilo} {
    ${soloDesign.headlineFontSizeMobile &&
    css`
      font-size: ${soloDesign.headlineFontSizeMobile};
      word-break: break-word;
      text-wrap: balance;
    `}
    ${soloDesign.headlineLineHeightMobile &&
    css`
      line-height: ${soloDesign.headlineLineHeightMobile};
    `}
  }
`;

const StyledHeadline = styled(Headline, {
  shouldForwardProp: (prop) => prop === 'as' || isPropValid(prop),
})(headlineSoloStylesMobile, headlineSoloStyles);

/**
 * A headline and descriptions that can be displayed above most sections.
 */
function SectionHeader({
  headline = '',
  content = {},
  alignment,
  index,
  className,
  trackingContentEntry = {},
  soloDesign = {},
  fullWidthHeadline = false,
  styledHeadline = {},
}) {
  if (!headline && isEmpty(content)) {
    return null;
  }

  const isFirst = index === 0;
  const isLeftAligned = alignment === ALIGNMENT.LEFT;
  const isFullWidth = isLeftAligned || fullWidthHeadline;
  const headlineProps = {
    as: isFirst ? 'h1' : 'h2',
    soloDesign,
    dataSelector: dataSelector('heading', 'section-header'),
  };

  const styledHeadlineContent = get('content[0].content', styledHeadline);
  const asRichtext = styledHeadlineContent
    ? !!styledHeadlineContent[0].value.length
    : false;

  return (
    <StyledRow
      className={className}
      alignment={alignment || ALIGNMENT.CENTER}
      data-selector={dataSelector('container', 'section-header')}
    >
      <Col
        {...pageWidthColProps}
        span={{
          default: 12,
          kilo: isFullWidth ? 12 : 10,
          mega: isFullWidth ? 12 : 8,
        }}
        skip={{
          default: 0,
          kilo: isFullWidth ? 0 : 1,
          mega: isFullWidth ? 0 : 2,
        }}
      >
        {!asRichtext ? (
          <StyledHeadline
            {...headlineProps}
            size="one"
            id={slugify(headline)}
            data-selector={dataSelector('heading', 'section-header')}
          >
            {headline}
            {soloDesign.headlineSecondLine && <br />}
            {soloDesign.headlineSecondLine || ''}
          </StyledHeadline>
        ) : (
          <>
            <RichText
              richText={styledHeadline}
              renderNode={sectionHeader.headlineConfig.createRenderNode(
                headlineProps,
              )}
              renderMark={sectionHeader.headlineConfig.createRenderMark}
            />
            {soloDesign.headlineSecondLine && <br />}
            {soloDesign.headlineSecondLine || ''}
          </>
        )}
        {content && (
          <RichText
            richText={content}
            renderNode={sectionHeader.contentConfig.createRenderNode(
              'one',
              trackingContentEntry,
              { text: soloDesign, as: 'p' },
            )}
            renderMark={sectionHeader.contentConfig.createRenderMark()}
          />
        )}
      </Col>
    </StyledRow>
  );
}

SectionHeader.ALIGNMENT_LEFT = ALIGNMENT.LEFT;
SectionHeader.ALIGNMENT_CENTER = ALIGNMENT.CENTER;

SectionHeader.propTypes = {
  className: PropTypes.string,
  /**
   * A short headline that can also be linked to as an anchor.
   */
  headline: PropTypes.string,
  /**
   * A concise description of the section with support for basic rich text.
   */
  content: richTextPropType,
  /**
   * Choose between left and center alignment.
   */
  alignment: PropTypes.oneOf([ALIGNMENT.LEFT, ALIGNMENT.CENTER]),
  /**
   * The current section index. If the section is the first on the page,
   * the headline uses the h1 element and becomes slightly larger.
   */
  index: PropTypes.number,
  /**
   * Information for analytics tracking event.
   */
  trackingContentEntry: trackingContentEntryPropType,
  /**
   * Since the color of the heading is provided explicitly in circui-ui
   * we want to override it so we can display our components properly
   */
  unsetColor: PropTypes.bool,
  /*
   * TEMPORARY EXPERIMENTAL SOLO STYLES EXTENSIONS!
   * added via https://sumupteam.atlassian.net/browse/SA-32606
   * TBD: will be removed or converted to proper features
   */
  soloDesign: PropTypes.object,
  /**
   * Define whether the header should extend the entire width or not
   */
  fullWidthHeadline: PropTypes.bool,
  /**
   * A short headline with support for basic rich text.
   */
  styledHeadline: PropTypes.object,
};

/**
 * @component
 */
export default SectionHeader;
